<template>
    <section class="header-contact">
        <section class="options-header header-left">
            <div>
                <img src="@/assets/icons/icon-call.svg" alt="icon-call"/>
                <p>(01) 224-8385 &nbsp; &nbsp; (ATENCION 24 HORAS)</p>
            </div>
            <!--<div>
                <img src="@/assets/icons/icon-mail.svg" alt="icon-main"/>
                <p>huanuco@skfhuanuco.com</p>
            </div>-->
        </section>

        <section class="options-header header-rigth">
            <div>
                <img src="@/assets/icons/icon-distance.svg" alt="icon-call"/>
                <p>Av. Aviacion 1954 Urb. Santa Catalina Lima, Perú</p>
            </div>
        </section>
        
    </section>
</template>

<style lang="scss" scoped>
.header-contact {
    padding-inline: $paddingLarge;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 100;

    padding-block: 10px; 

    color: $whited;
    background-color: $shade3;
    width: 100%;

    .options-header div {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }


    .header-left {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .header-right {
        display: flex;
        flex-direction: row;
    }
}


@media (max-width: 1360px) {
    .header-contact {
        padding-inline: 80px;
    }
}


@media (max-width: 1200px) {
    .header-contact {
        display: none;
    }
}

</style>