<template>
    <article @click="navCategorieCatalogue(dataCategorie.name)" class="card-catalogue" :class="{selected: isSelected?.toLowerCase() == dataCategorie.name.toLowerCase()}">
        <figure>
              <img :src="dataCategorie.img ? dataCategorie.img : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png'" :alt="` Imagen de ${dataCategorie.name}`" v-if="showImage"/>
              <p v-if="!dataCategorie.img && showImage" class="alt-nameproduct">{{ dataCategorie.name.toUpperCase() }} </p>
              <figcaption  v-if="!showImage" >
                <p>{{ dataCategorie.name }}</p>
              </figcaption>
        </figure>
    </article>
</template>

<script lang="ts" setup>
import {defineProps} from 'vue';
import { useNavbarStore } from "@/store/navbar";
import router from "@/router";

const navStore = useNavbarStore();

defineProps({
    dataCategorie: {
        type: Object,
        required: true
    },
    isSelected: {
      type: String
    },
    showImage: {
      type: Boolean
    }
})

function navCategorieCatalogue(categorie: string) {
  router.push({name: `catalogueCategorie`, params: {
    categorie: categorie.toLowerCase()
  }});
  navStore.changeStyleNavbar("light");
}

</script>

<style lang="scss" scoped>
    .card-catalogue {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
        figure {
          display: flex;
          flex-direction: column;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              
            }
            figcaption {
              border: 1px solid $tint4;
              border: 1px solid #cee3f659;
              background-color: #cee3f61c;
              cursor: pointer;
                display: flex;
                padding-block: 20px;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 10px;
                text-align: center;
              transition: 0.35s ease-in;
              margin: auto 0;

                p {
                  color: $shade1;
                  font-size: $Txmedium;
                  font-weight: 400;
                }
            }
            figcaption:hover {
              background-color: $tint4;
            }
            .alt-nameproduct {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 20%;
            }
        }


    }

    .card-catalogue.selected {
      figure {
      background-color: $colorPrimary;
      p {
        color: $whited;
      }
      }
    }
</style>