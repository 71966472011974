<template>
        <section class="section-info">
          <aside class="content-text-info">
        <p>Confianza y Seguridad en nuestros Productos</p>
        <h2>Razones para Elegirnos
        </h2>
        <p>Al elegir nuestros servicios, te beneficias de más de 35 años de experiencia en la distribución de repuestos industriales y automotrices. Garantizamos productos de alta calidad y disponibilidad inmediata para los sectores automotriz, minero y agrícola.<br /> <br />
          Nuestro equipo de expertos te ofrece asesoría personalizada y soluciones adaptadas a tus necesidades.
        <br /> <br />No pierdas más tiempo, contáctanos a través de nuestros diversos canales.
        <a href="https://linktr.ee/rodamientos_huanuco?utm_source=linktree_profile_share&ltsid=b888cc42-6edc-4575-8e07-990f2d045897" target="_blank">  Haz clic aquí para contactarnos.</a> </p>
          </aside>
          <div class="image-section-info">
            <img src="https://res.cloudinary.com/dyikvzcke/image/upload/v1729744417/rodamientoshuanuconostros_au2zn2.jpg" alt="Equipo de trabajo especializado">
          </div>
    </section>

</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
@import './SectionBenefits.style.scss';

</style>