<template>
    <section class="view-categorie-detail">
  
      <h1>PRESENTACION DE <span>{{ $route.params.categorie ?  `${$route.params.categorie}` : ''}}</span></h1>
       
      <div class="section-filter">
        <swiper
        :modules="modules"
        :slidesPerView="5"
        :spaceBetween="10"
        :navigation="true"
        :virtual="true"
        :breakpoints="{
              0: {
                slidesPerView: 2
              },
              600: {
                slidesPerView: 4
              },
              980: {
                slidesPerView: 5
              },
              1200: {
                slidesPerView: 6
              },
              1420: {
                slidesPerView: 7
              }
            }"
        class="mySwiper"
      >
        <swiper-slide
        v-for="(item, index) in categories"
          :key="index"
          :virtualIndex="index"
          >
          <CardCatalogue :dataCategorie="item" :isSelected="$route.params.categorie as string" />
  
        </swiper-slide>
        </swiper>
      </div>
  
      <section class="main-categorie">
  
        <aside class="list-subcategories">
  
        
          
    <div>
      <input type="radio" id="automotriz" name="drone" value="automotriz" />
      <label for="automotriz"> Automotriz</label>
    </div>
          
    <div>
      <input type="radio" id="industrial" name="drone" value="industrial" />
      <label for="industrial"> Industrial</label>
    </div>
  
  
        </aside>
  
        <aside class="items-catagoue" v-if="$route.params.categorie">
          <section v-if="dataMain.productsCatalogue[$route.params.categorie.toUpperCase()] as string" class="grid-items">
        <CardArticleProduct
          v-for="(item, index) in dataMain.productsCatalogue[$route.params.categorie.toUpperCase()] as string" :key="index" :object-product="item" />
          </section>
          <section v-else>
            <p>No hay productos para mostrar</p>
          </section>
        </aside>
        <aside class="body-catalogue" v-else>
          Seleccionar algun repuesto
        </aside>
        
      </section>
  
  
    </section>
  </template>
  
  <script setup lang="ts">
  import CardArticleProduct from "@/components/shared/cards/CardArticleProduct.vue";
  import dataMain from "@/data/dataMain.json";
  import { ref } from "vue";
  import data from '@/data/dataMain.json';
  
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  
  // Import Swiper styles
  import 'swiper/css';
  
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';
  import 'swiper/css/virtual';
  
  import { Pagination, Navigation, Virtual } from 'swiper/modules';
  import CardCatalogue from "@/components/shared/cards/CardCatalogue.vue";
  
  
  
  let modules = ref([Pagination, Navigation, Virtual])
  
  let categories = ref(data.categories)
  
  
  
  </script>
  
  
  
  <style scoped lang="scss">
  @import './Catalogue.style.scss';
  </style>