<template>

  <VideoBackground />
  
  <div class="section-main">
      <div class="content-section-main">
        <div class="content">
          <div>
            <h2><img src="https://res.cloudinary.com/dyikvzcke/image/upload/v1729912683/skf_fhaoeg.png"  alt="Icono de distribuidor Autorizado de SKF"/> </h2>
            <h1>Impulsa tu camino con nuestros repuestos
            </h1>
          </div>
          <h2 class="subtitle">
            Expertos en Repuestos para la Industria y Automotriz: <span>Calidad, Variedad y Asesoría Especializada</span>
            
            <!-- Líderes en el mercado de repuestos para diversas industrias: <br /> <span>Automotriz, Minera, Agrícola y más.</span>-->
          </h2>
        </div>

        <div class="section-btn">
          <div>
            <ButtonAction :type-button="'btn btn-secondary'" :view="'productos'">
                <img src="@/assets/icons/icon-catalogue.svg" /> Ver productos
            </ButtonAction>
          </div>
          <div>
            <ButtonAction :type-button="'btn btn-primary'">
              <img src="@/assets/icons/icon-shopping_bag.svg" />
              Comprar producto
            </ButtonAction>
            <i>* Proximamente</i>
          </div>
        </div>

        <a href="#brands" class="buttom-lower">
          <img src="@/assets/icons/icon-bottom.svg" alt="icon-bottom" class="arrow-lower">
        </a>
      </div>

  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
import ButtonAction from '@/components/shared/button/ButtonAction.vue';



const VideoBackground = defineAsyncComponent({
  loader: () => import('@/components/videoBackground/VideoBackground.vue'),
})

</script>

<style lang="scss" scoped>
@import './SectionHeroMain.style.scss';
</style>