import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

/* VISTAS DE PAGINAS DE ATERRIZAJE */ 
import HomeView from '@/views/HomeView.vue'
import ContactView from '@/views/ContactView.vue'
import CatalogueView from '@/views/catalogue/CatalogueView.vue'
import NotFoundView from '@/views/NotFoundView.vue'

/* VISTAS DE TIENDA */
import ProductDetailStoreView from '@/views/store/ProductDetailStoreView.vue' 
import ShoppingCartStoreView from '@/views/store/ShoppingCartStoreView.vue'
import CheckoutStoreView from '@/views/store/CheckoutStoreView.vue'
import HomeStoreView from '@/views/store/HomeStoreView.vue'

/* COMPONENTES DE NAVEGACION */ 
import NavWeb from '@/components/nav/nav-web/NavWeb.vue'


/* EXTRAS */
//import GenerateCatalogue from '@/views/GenerateCatalogue.vue' ;
import NavStore from '@/components/nav/nav-store/NavStore.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    components: {
      default: HomeView,
      menu: NavWeb
    },
    meta: {
      title: '',
      description: 'Rodamientos Huánuco: expertos en repuestos industriales y automotrices. Ofrecemos soluciones de alta calidad con más de 35 años de experiencia. ¡Mejora el rendimiento de tus equipos con nosotros!'
    }
  },
  {
    path: '/acerca-de',
    name: 'acerca-de',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      menu: NavWeb
    } ,
    meta: {
      title: 'Acerca de',
      description: 'Fundado en 1989, Rodamientos Huánuco brinda el mejor servicio en todo el Perú.'
    }
  },
  {
    path: '/productos',
    name: 'productos',
    components: {
      default: CatalogueView,
      menu: NavWeb
    },
    meta: {
      title: 'Productos',
      description: 'Descubre rodamientos y repuestos industriales de calidad en Rodamientos Huánuco. Con más de 35 años de experiencia, garantizamos durabilidad y rendimiento para maquinaria automotriz e industrial.'
    }
  },
  {
    path: '/productos/:categorie',
    name: 'catalogueCategorie',
    components: {
      default: CatalogueView,
      menu: NavWeb
    },
    meta: {
      title: 'Productos',
      description: `Adquiere nuestros ${window.location.pathname.split('/')[2]} con el mejor precio, diseñado para mejorar el rendimiento de tu maquinaria. Con materiales de alta calidad, garantiza durabilidad y eficiencia en diversas aplicaciones. ¡Optimiza tus proyectos hoy!`
    }
  },
  {
    path: '/contacto',
    name: 'contact',
    components: {
      default: ContactView,
      menu: NavWeb
    },
    meta: {
      title: 'contacto',
      description: 'Contáctanos en Rodamientos Huánuco. Estamos aquí para asesorarte en la compra de repuestos industriales y automotrices de alta calidad. ¡Habla con nuestros expertos y recibe atención personalizada!'
    }
  },
  /*{
    path: '/generar-catalogo',
    name: 'generarCatalogo',
    components: {
      default: GenerateCatalogue,
      menu: NavWeb
    },
    meta: {
      title: 'Generar Catalogo'
    }
  },*/
  {
    path: '/tienda',
    name: 'store',
    meta: {
      title: '',
      description: 'Explora nuestra tienda en Rodamientos Huánuco y descubre una amplia gama de rodamientos y repuestos industriales y automotrices. Ofrecemos productos de calidad y precios competitivos para satisfacer todas tus necesidades. ¡Compra ahora y optimiza tu maquinaria!',
      redirect: '/'
    },
    components: {
      default: HomeStoreView,
      menu: NavStore,
    },
    children: [
      {
        path: 'detalle-producto/:id',
        component: ProductDetailStoreView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'carrito-de-compras',
        component: ShoppingCartStoreView
      },
      {
        path: 'proceso-de-pago',
        component: CheckoutStoreView,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      title: '',
      description: 'Pagina no encontrada',
    },
    components: {
      default: NotFoundView,
      menu: NavWeb,
    },
  }
  
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const title: string | undefined = to.meta.title as string | undefined;
  const description: string | undefined = to.meta.description as string || 'Rodamientos Huánuco, Distribuidores de repuestos industriales y automotrices';

  const metaDescription = document.querySelector('meta[name="description"]');


  redirectTiendaToHome(to, from, next);


  if (to.meta.title) {
    document.title = `Rodamientos Huanuco | ${title}`;
    metaDescription?.setAttribute('content', description);
  }else {
    document.title = `Rodamientos Huanuco`;
    metaDescription?.setAttribute('content', description);
  }
  next();

});


function redirectTiendaToHome(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if(to.meta.path != to.meta.redirect) {
    next('/');
  }
  next();
}

export default router