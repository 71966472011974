<template>
    <section class="section-services">
      <h2>NUESTROS SERVICIOS</h2>
      <section class="services">
        <article>
          <div  class="service-icon">
            <img src="@/assets/icons/icon-venta.svg" alt="Icono de productos de calidad" width="80"/>
          </div>
          <div class="service-text">
            <h6>Ventas precenciales</h6>
            <p>Ofrecemos los mejores productos en las mejores marcas.</p>
          </div>
        </article>
        <article>

          <div class="service-icon">
            <img src="@/assets/icons/icon_local_shipping.svg" alt="Icono de pedidos y envios" width="80"/>
          </div>
          <div class="service-text">
            <h6>Pedidos y envios</h6>
            <p>Hacemos envios a todo el Perú, y envios gratis a diferentes distritos de Lima.</p>
          </div>
        </article>
        <article>
          <div  class="service-icon">
            <img src="@/assets/icons/icon_category.svg" alt="Icono de productos de calidad" width="80"/>
          </div>
          <div class="service-text">
            <h6>Ventas al por mayor y menor</h6>
            <p>Listos para ofrecer todos los productos que nuestros clientes necesitan.</p>
          </div>

        </article>
        <article>

          <div  class="service-icon">
            <img src="@/assets/icons/icon-variedad-productos.svg" alt="Icono de productos de calidad" width="80"/>
          </div>
          <div class="service-text">
            <h6>Variedad de productos</h6>
            <p>Ofrecemos los mejores productos en las mejores marcas.</p>
          </div>
        </article>
      </section>
    </section>
</template>


<style lang="scss" scoped>
@import "./SectionServices.style.scss";
</style>