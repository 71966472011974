<template>
    <section class="section-brands">
      <div class="text-section-brands">
  
      </div>
      
      <div class="section-content-brands">
        <swiper :modules="modules" :spaceBetween="10" :navigation="true" :virtual="true" :breakpoints="{
            0: {
              slidesPerView: 1
            },
            300: {
              slidesPerView: 3
            },
            380: {
              slidesPerView: 4
            },
            600: {
              slidesPerView: 5
            },
            980: {
              slidesPerView: 6
            },
            1200: {
              slidesPerView: 8
            },
            1420: {
              slidesPerView: 10
            }
          }" class="mySwiper">
            <swiper-slide v-for="(item, index) in brands" :key="index" :virtualIndex="index">
              <BrandIcon :urlImg="item.img" class="item-brand" />
            </swiper-slide>
          </swiper>
      </div>
    </section>
  </template>
  
  <script lang="ts" setup>
  import BrandIcon from '@/components/shared/brand/BrandIcon.vue';
  import { ref } from 'vue';
  import dataMain from '@/data/dataMain.json';
  
  // SWIPER
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination, Navigation, Virtual } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';
  import 'swiper/css/virtual';
  
  
  let modules = ref([Pagination, Navigation, Virtual])
  
  const brands = ref(dataMain.brands);
  
  </script>
  
  
  <style lang="scss" scoped>
  @import './SectionBrands.style.scss';
  </style>