<script setup lang="ts">

function showBoxMessage() {
  const usersWhatsApp = [998247832, 998247830, 993381769]
  const linkWhatsApp = `https://wa.me/51${usersWhatsApp[Math.floor(Math.random() * usersWhatsApp.length)]}?text=Hola%20como%20estas`;
  window.open(linkWhatsApp, '_blank');
}

</script>

<template>
  <div class="blond-whatsapp">

    <div class="alert-message" @click="showBoxMessage()">
      <img src="@/assets/icons/whatsapp-link.svg" alt="Icon WhatsApp" />
    </div>

  </div>
</template>

<style scoped lang="scss">
.blond-whatsapp {
  position: fixed;
  z-index: 50;
  left: 30px;
  bottom: 15px;

  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  
  .alert-message {
    //animation: 1.8s ease-in scaleWhatsapp infinite;
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 180px;
    }
    p {
      font-size: 18px;
      font-weight: 200;
      color: $tint1;
    }
  }


}


@keyframes scaleWhatsapp {
  0% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}


</style>