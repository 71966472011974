<template>
    <div class="contact">
        <p class="meta-contact">CONTACTO</p>

        <div class="content-contact">
            <section class="info-contact">
            <div class="info-item info-contact-location">
                <h4>Ubicación</h4>
                <p>Av. Aviación 1954 Urb. Santa Catalina Lima - 13 Perú. <br />
(Referencia) FRENTE A LA VIDENA DEPORTIVA.</p>
            </div>

            <div class="info-item info-contact-hour">
            <h4>Horario de atención</h4>
                <p>Lunes a Viernes 8AM - 6PM <br />
Sábados 8AM - 3PM</p>
            </div>
            <div class="info-item info-contact-me">
            <h4>Información personal</h4>
                <p>huanuco@skfhuanuco.com <br />
(01) 224-8385 <br />
+51 998247830 </p>
            </div>

            </section>
            <section class="map-contact">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.440963068274!2d-77.00772532493826!3d-12.081935488157626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c62b7bc895d3%3A0x376f7a3d2e81c76e!2sRodamientos%20Hu%C3%A1nuco%20E.I.R.L.!5e0!3m2!1ses!2spe!4v1718832731765!5m2!1ses!2spe" width="600" height="450" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </div>

    </div>
</template>


<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.contact {
    padding-top: 120px;
    padding-inline: $paddingLarge;
    color: $blacked;
    text-align: start;

    .content-contact {
        display: flex;
        flex-direction: row;
        align-items: start;
        gap: 80px;
        margin-top: 100px;
    }

    .meta-contact {
        color: $tint3
    }

    .info-contact {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
        font-size: $Txmedium;
        color: $shade1;
        .info-item {
            display: flex;
            flex-direction: column;
            gap: 10px;
            h4 {
                color: $shade4;   
            }
            p {
                opacity: 0.7;
            }
        }

    }

    .map-contact {
        width: 100%;

        iframe {
            width: inherit;
        }
    }

}

@media (max-width: 1300px) {

    .contact {
        padding-inline: 80px;
        .content-contact {
            display: flex;
            flex-direction: column;
        }
    }
}


@media (max-width: 820px) {
}

@media (max-width: 630px) {
    .contact {
        padding-inline: 32px;
    }
}
</style>