<template>
    <section class="section-catalogue">
      <div class="products">
  
        <div class="header-section-catalogue">
        <div class="text-catalogue">
          <p>Presentamos</p>
          <h2>Nuestros Productos</h2>
        </div>
      </div>
      <div class="content-cards-catalogue">
        <div class="cards">
          <swiper :modules="modules" :spaceBetween="20" :navigation="true" :virtual="true" :breakpoints="{
            0: {
              slidesPerView: 2
            },
            600: {
              slidesPerView: 2
            },
            980: {
              slidesPerView: 3
            },
            1200: {
              slidesPerView: 3
            },
            1420: {
              slidesPerView: 4
            }
          }" class="mySwiper">
            <swiper-slide v-for="(item, index) in repuestos" :key="index" :virtualIndex="index">
              <CardCatalogue :dataCategorie="item" :showImage="true"/>
  
            </swiper-slide>
          </swiper>
        </div>
        <div class="cards">
          <swiper :modules="modules" :slidesPerView="5" :spaceBetween="30" :navigation="true"
            :virtual="true" :breakpoints="{
              0: {
                slidesPerView: 2
              },
              600: {
                slidesPerView: 2
              },
              980: {
                slidesPerView: 3
              },
              1200: {
                slidesPerView: 3
              },
              1420: {
                slidesPerView: 4
              }
            }" class="mySwiper">
            <swiper-slide v-for="(item, index) in outilsSKF" :key="index" :virtualIndex="index">
              <CardCatalogue :dataCategorie="item"   :showImage="true"/>
  
            </swiper-slide>
          </swiper>
        </div>
      </div>
      </div>
      <div class="bearing">
      </div>
    </section>
  </template>
  
  <script lang="ts" setup>
  import { ref, Ref } from "vue";
  
  // COMPONENTS
  import CardCatalogue from "@/components/shared/cards/CardCatalogue.vue";
  import data from '@/data/dataMain.json';
  //import { categorie } from "@/types/TypeCategorie";
  
  // SWIPER
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination, Navigation, Virtual } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';
  import 'swiper/css/virtual';
  
  // STYLE
  import './SectionCatalogue.style.scss';
  
  let modules = ref([Pagination, Navigation, Virtual])
  const repuestos: Ref = ref([])
  const outilsSKF: Ref = ref([])
  
  outilsSKF.value = data.categories.filter(item => item.type === "others")
  repuestos.value = data.categories.filter(item => item.type != "others")
  
  
  </script>
  
  <style lang="scss" scoped>
  @import './SectionCatalogue.style.scss';
  </style>