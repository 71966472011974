<template>
    <section class="section section-404">
        <div class="image image-404">
            <p>4</p>
            <img src="@/assets/images/404.svg" alt="Rodamiento de Rodamientos Huanuco" />
            <p>4</p>
        </div>
        <div class="text">
            <h3>Página no encontrada</h3>
            <p>La página que buscas no existe o ha sido movida.</p>
            <router-link to="/">Volver a la página principal</router-link>
        </div>
    </section>
</template>

<script lang="ts" setup>
</script>



<style lang="scss"  scoped>
.section-404 {
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}
.section-404 .image-404 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    p {
        font-size: 200px;
    }
    img {
        width: 160px;
    }
}
.section-404 .text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    h3 {
        font-size: $Hxsmall;
    }

    a {
        color: $tint1;
    }
}

@media (max-width: 630px) {
    .section-404 .image-404 {
        gap: 10px;
        p {
            font-size: 100px;
        }
        img {
            width: 80px;
        }
    }
    .section-404 .text {
        h3 {
        font-size: $Tlarge;
    }
        p {
        font-size: $Tmedium;
        opacity: 0.8;
    }

    a {
        color: $tint1;
        font-size: $Tregular;
    }
    }
}
</style>