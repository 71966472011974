<template>


    <footer>
        <section class="section-footer-me">
          <h6>Sobre nosotros Rodamientos huanuco</h6>
            <p>Fundado en 1989, Rodamientos Huánuco brinda el mejor servicio en todo el Perú. Nuestros profesionales altamente capacitados y el constante esfuerzo por mejorar nuestros procedimientos, nos sitúan primeros... seguir Leyendo</p>
            <div class="social-media">
                <p>Nuestras redes sociales</p>
                <div>
                    <a href="https://www.facebook.com/rodamientoshuanuco.ramos/" target="_blank">
                        <img src="@/assets/icons/facebook_symbol.svg.svg" alt="Logo Facebook" class="logo-social" width="40"/>
                    </a>
                    <a href="#" target="_blank">
                        <img src="@/assets/icons/logo_instagram.svg" alt="Logo Instagram" class="logo-social" width="40"/>
                    </a>
                    <a target="_blank">
                        <img src="@/assets/icons/logo_tiktok.svg" alt="Logo TikTok" class="logo-social" width="40"/>
                    </a>
                </div>
            </div>
        </section>


        <section class="section-footer-pages">
          <h6>Mapa del sitio</h6>
          <ul>
            <li>
              <router-link
          to="/"
          class="item-option"
          >Inicio</router-link
        >
            </li>
            <li>
              <router-link
          to="/productos"
          class="item-option"
          >Productos</router-link
        >
            </li>
            <li>
              <router-link
          to="/acerca-de"
          class="item-option"
          >Acerca de</router-link
        >
            </li>
            <li><a><router-link
          to="/contacto"
          class="item-option"
          >Contacto</router-link
        ></a></li>
          </ul>
        </section>
       
        <section class="section-footer-contact">
            <h6>Contactanos</h6>
            <div>
                <p>
                    Av. Aviacion 1954 Urb. Santa Catalina Lima - 13
                    Perú. (Referencia) FRENTE A LA VIDENA DEPORTIVA.
                </p>
                <p>
                    (01) 224-8385
                </p>
                <p>+51 998247830</p>
                <p>ventas@skfhuanuco.com</p>
                <p>huanuco@skfhuanuco.com</p>
                <p>Lunes - Vier 8AM - 6PM <br />
Sábados 8AM - 3PM</p>
            </div>
        </section>
    </footer>



</template>

<script lang="ts" setup>
</script>


<style lang="scss" scoped>
    footer {
        width: 100%;
        background-color: $shade1;
        color: $whited;
        margin-top: 150px;
        display: flex; 
        flex-direction: row;
        gap: 100px;
        padding-inline: $paddingLarge;
        padding-block: 80px;

        .section-footer-me {
            flex: 1;
            display: flex;
            flex-direction: column;
            text-align: start;
            gap: 20px;

            h6 {
              font-size: $Txmedium;
            font-weight: 400;
            color: $whited;
          }

          p {
            font-size: $Tmedium;
            color: $whited;
            opacity: 0.6;
          }


            .social-media {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                gap: 20px;

              p {
                font-weight: 400;
                color: $whited;
                opacity: 0.8;
              }

                div {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                }
            }
        }

        .section-footer-pages {
            flex: 1;
            display: flex;
            flex-direction: column;
            text-align: start;
            gap: 20px;

          h6 {

            font-size: $Txmedium;
            font-weight: 400;
            color: $whited;
          }

            ul {
                text-align: start;
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 20px;
                list-style: none;
            }

            ul li a {
                list-style: none;
              color: $whited;
              opacity: 0.8;
              
              font-weight: 300;
            }
        }

        .section-footer-contact {
            flex: 1;
            display: flex;
            flex-direction: column;
            text-align: start;
            gap: 20px;

          h6 {
            font-size: $Txmedium;
            font-weight: 400;
            color: $whited;
          }

            div {
                display: flex;
                flex-direction: column;
                gap: 10px;
              color: $whited;
              opacity: 0.6;
                font-weight: 300;
            }
        }
        

    }


    @media (max-width: 1300px) {
      footer {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
          gap: 50px;
          row-gap: 100px;
          padding-inline: 100px;
        }
    }

    @media (max-width: 1100px) {

        /* RESET DEL FOOTER */
       
    
    }

    @media (max-width: 630px) {
        footer {
            padding-inline: 32px;
            justify-content: center;
        }
    }
</style>