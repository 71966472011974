<template>
    <button :class="typeButton" @click="navigation(view)">
        <slot></slot>
    </button>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';
import router from "@/router"

defineProps({
    typeButton: {
        type: String,
    },
    view: {
        type: String
    }
})

function navigation(view) {
    router.push({name: view});
}

</script>

<style lang="scss" scoped>

.btn {

    &-primary {
    padding-block: 10px;
    padding-inline: 50px;
    font-family: $fontFamily;
    font-size: 18px;
    border-radius: 40px;
    border: 2px solid $colorPrimary;
    outline: none;
    color: $whited;
    display: flex;
    align-items: center;
    background: $colorPrimary;
    }
    &-secondary {
        
    padding-block: 10px;
    padding-inline: 50px;
    font-family: $fontFamily;
    border-radius: 40px;
    outline: none;
    border: none;
    font-size: 18px;
    border: 2px solid $tint3;
    color: $tint3;
    display: flex;
    align-items: center;
    gap: 10px;
    background: none;
    }
}

@media (max-width: 1100px) {
    .btn {
        font-size: 16px;
        padding-block: 8px;
    }
}

@media (max-width: 630px) {
  .btn {
    justify-content: center;
    padding-inline: 20px;
  }
}


</style>