<template>
  <BarContact />
  <div class="content-nav">
    <nav ref="navbar" class="navbar transparent">
      <div class="shade-nav" ref="shadeMenu"></div>

      <div class="options options-left">
        <router-link to="/">
          <img
            src="https://res.cloudinary.com/dyikvzcke/image/upload/v1727023877/logo-rodamientoshuanuco-removebg-preview_fyvt3v.png"
            alt="Logo Rodamientos Huanuco"
            class="logo-large"
          />
          <img
            src="../../../../public/logo-rodamientos-huanuco.png"
            alt="Logo Rodamientos Huanuco"
            class="logo-small"
          />
        </router-link>
      </div>

      <div class="options options-right" ref="optionsMenu">

        <!--
        <div class="item-option item-search">
          <img src="@/assets/icons/icon-search.svg" alt="Icono de busqueda" />
          <input type="search" placeholder="Buscar..."/>
        </div>
        -->
        <div class="item-option item-search">
          <img src="@/assets/icons/icon-call.svg" alt="Icono de busqueda" />
          <p>998 247 832 </p>
        </div>
        <div class="item-option item-search">
          <img src="@/assets/icons/icon-mail.svg" alt="Icono de busqueda" />
          <p>huanuco@skfhuanuco.com</p>
        </div>
      </div>


     
      <div class="btn-menu">
        <button @click="showMenu()">
          <img
            src="@/assets/icons/icon-menu.svg"
            alt="Menu"
            class="menu"
            ref="btnMenuBar"
          />
          <img
            src="@/assets/icons/icon_close.svg"
            alt="Close"
            class="close"
            ref="btnMenuClose"
          />
        </button>


    

      </div>





    </nav>




 


  </div>


   <div class="content-menubar" ref="optionsMenu">
        <a ref="btnMenuClose" class="item-close-menu"  @click="showMenu()"><img src="@/assets/icons/icon_close.svg" /></a>

        <!-- 
        <div class="item-option item-option--catalogue">
          <a to="/productos" class="option" @click="(e) => showOptionsCatalogue(e)">Productos <img src="@/assets/icons/icon-arrow_drop_down.svg"/> </a>
          
          <div class="options options-catalogue" ref="optionsCatalogue">
              <ul>
                <li v-for="(item, index) in categories" :key="index"><router-link
                class="option-main"
                :to="`/productos/${item.categorie}`"
                >{{ item.categorie }}</router-link
              ></li>
              </ul>    
          </div>
        </div>
-->
        
        <router-link
          to="/productos"
          class="item-option"
          @click="clickOptionMenu()"
          >Productos</router-link
        >


        <router-link
          to="/acerca-de"
          class="item-option"
          @click="clickOptionMenu()"
          >Acerca de</router-link
        >
        <router-link
          to="/contacto"
          class="item-option"
          @click="clickOptionMenu()"
          >Contacto</router-link
        >
        <router-link to="/tienda" class="btn btn-store">Tienda</router-link>
    </div>



  

 


</template>

<script lang="ts" setup>
import BarContact from "@/components/shared/bars/BarContact.vue";
import { ref, Ref, onMounted, onUpdated } from "vue";
import { useRoute } from "vue-router";
import { useNavbarStore } from "@/store/navbar";
import data from "@/data/dataMain.json";
import './NavWeb.style.scss';

const route = useRoute();
const storeNavbar = useNavbarStore();

let optionsMenu = ref<HTMLElement | null>(null);
let btnMenuBar = ref<HTMLElement | null>(null);
let btnMenuClose = ref<HTMLElement | null>(null);
let shadeMenu = ref<HTMLElement | null>(null);
let navbar: Ref<HTMLElement | null> = ref(null);
//let optionsCatalogue = ref<HTMLElement | null>(null);
const categories = ref();



categories.value = data.categories.map((item) => {
  return data.productsCatalogue[item.name.toUpperCase()]
    ? {
        categorie: item.name,
        subCategories: data.productsCatalogue[item.name.toUpperCase()],
      }
    : { categorie: item.name };
});


function clickOptionMenu(): void {
  if (optionsMenu.value?.className.includes("active")) {
    optionsMenu.value?.classList.remove("active");
  }
}

function changeBgNavbarTwo() {
  if (route.name != "home") {
    storeNavbar.changeStyleNavbar("light");
  } else {
    storeNavbar.changeStyleNavbar("transparent");
  }

  if (navbar.value) {
    navbar.value.className = `navbar ${storeNavbar.style}`;
  }
}

onMounted(() => {
  changeBgNavbarTwo();
});

onUpdated(() => {
  changeBgNavbarTwo();
})



function showMenu() {
  // Close Menu
  if (optionsMenu.value?.className.includes("active")) {
    optionsMenu.value?.classList.remove("active");
  }
  // Open Menu
  else {
    optionsMenu.value?.classList.add("active");
  }
}

/*function showOptionsCatalogue(e): void {
  if (optionsCatalogue.value?.className.includes("active")) {
    optionsCatalogue.value?.classList.remove("active");
    return;
  }
  optionsCatalogue.value?.classList.add("active");
}*/


function detectedScrollBgNavbar() {
  window.addEventListener("scroll", () => {
  if (route.name == "home") {
    //const screenHeight = window.screen.availHeight;

    if (window.scrollY >= 10) {
      storeNavbar.changeStyleNavbar("light");
    } else {
      storeNavbar.changeStyleNavbar("transparent");
    }

    if (navbar.value) {
      navbar.value.className = `navbar ${storeNavbar.style}`;
    }
  }
});
}
detectedScrollBgNavbar();

</script>

