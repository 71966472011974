<template>
  <article>
    <figure>
      <img :src="objectProduct.imgUrl ? objectProduct.imgUrl : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png'" alt="Imagen" />
      <figcaption>
        <div class="product-text">
          <p class="product-name">{{ objectProduct.name }}</p>
          <p class="product-type">{{ objectProduct.type }}</p>
          <p class="product-description">{{ objectProduct.description }}</p>
        </div>

        <div class="btn btn-request-catalogue">
          <button class="consulta" @click="sendMessageWhatsAppProduct($route.params.categorie as string, objectProduct.name)">
            Seguir consultando
          </button>
    <Modal
      v-model:open="modal2Visible"
      title="Solicitud de catalogo"
      centered
      @ok="modal2Visible = false"
    >
          <input type="email" placeholder="correo@gmail.com" style="width: 100%;padding-inline: 10px; border-radius: 10px; font-size: 18px;"/>
    </Modal>


        </div>
      </figcaption>
    </figure>
  </article>
</template>



<script setup lang="ts">
import { defineProps } from 'vue';
//import emailjs from '@emailjs/browser';
import { ref } from 'vue';
import { Modal } from 'ant-design-vue';

import 'ant-design-vue/dist/reset.css';

//const modal1Visible = ref<boolean>(false);
const modal2Visible = ref<boolean>(false);

/*const setModal1Visible = (open: boolean) => {
  modal1Visible.value = open;
};*/

defineProps({
  objectProduct: {
    type: Object,
    required: true
  }
})

/*function nextDetailCategorieView(current: string, old: string) {
  router.push({
    name: `catalogueSubCategorie`,
    params: {
      categorie: current,
      subcategorie: old
    }
  })
}*/

/*function sendEmail() {
  const templateParams = {
    user_email: "romario10raymundo@gmail.com",
    name: "Rodamientos Huanuco",
    surnames: "Huanuco", // Archivo PDF en base64
    };

    emailjs.send("service_p7vfhzp","template_4l1f7zb", templateParams)
    .then((result) => {
        console.log('Email sent:', result.text);
    },(error) => {
        console.log('Failed to send email:', error.text);
    });
}*/

function sendMessageWhatsAppProduct(categorie: string, type: string) {
  const usersWhatsApp = [998247832, 998247830, 993381769]
  const linkWhatsApp = `https://wa.me/51${usersWhatsApp[Math.floor(Math.random() * usersWhatsApp.length)]}?text=Hola Rodamientos Huanuco, me intersa ${categorie} de tipo ${type}`;
  window.open(linkWhatsApp, '_blank');
}

/*function sendMessageWhatsAppCatalogue(categorie: string, type: string) {
  const userWhatsApp = 998247832
  const linkWhatsApp = `https://wa.me/51${userWhatsApp}?text=Hola Rodamientos Huanuco, quiero solicitar el catalogo de ${categorie} de tipo ${type}`;
  window.open(linkWhatsApp, '_blank');
}*/


</script>




<style scoped lang="scss">
article {
  font-size: 18px;
  text-align: start;
  padding-inline: 25px;
  padding-block: 20px;
  color: $shade1;
  border-radius: 10px;
  figure {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 10px;
    figcaption {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .product-name {
        font-weight: 600;
        opacity: 0.8;
      }
      .product-description {
        font-size: $Tmedium;
        opacity: 0.8;
      }
      .product-status {
        font-size: $Tmedium;
        opacity: 0.8;
      }
      .product-text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .product-type {
          background-color: #1b5d932d;
          width: min-content;
          padding-inline: 10px;
          padding-block: 5px;
          opacity: 0.8;
          font-size: 16px;
          border-radius: 5px
        }
      }
      .product-chats {
        font-size: $Tregular;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        gap: 10px;
      }
      a {
        width: 30px;
        color: $tint1;
      }
      .btn-request-catalogue {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        button {
          width: 100%;
          padding-block: 10px;
          font-size: 16px;
          background: none;
          font-family: $fontFamily;
          font-weight: 300;
          border-radius: 20px;
          border: none;
          outline: none;
          opacity: 0.8;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 10px;
          cursor: pointer;
          img {
            width: 18px;
          }
        }
        .consulta {
          color: $tint1;
          font-weight: 500;
          text-decoration: underline;
          background-color: rgba(128, 128, 128, 0.089);
        }
        .catalogue {
          color: $shade1;
          font-weight: 400;
        }
      }
    }
  }
  figure img {
    width: 100%;
  }
}
</style>