<template>
  <section class="section-statistic">
    <div>
      <article>
        <div class="content-article">
          <p class="text-counter">+ {{ dataR.year }} </p>
          <p class="text-article">Años de experiencia</p>
        </div>
      </article>
      <article>
        <div class="content-article">
        <p class="text-counter">
          + {{ dataR.clients.toString().length > 4 ? dataR.clients.toString().slice(0,2) : dataR.clients.toString().slice(0,1) }},{{ dataR.clients.toString().length > 4 ? dataR.clients.toString().slice(2,5) : dataR.clients.toString().slice(1,5) }} </p>
        <p class="text-article">Clientes satisfechos</p>
      </div>
      </article>
      <article>
        <div class="content-article">

        <p class="text-counter">
          + {{ dataR.product.toString().length > 4 ? dataR.product.toString().slice(0,2) : dataR.product.toString().slice(0,1) }},{{ dataR.product.toString().length > 4 ? dataR.product.toString().slice(2,5) : dataR.product.toString().slice(1,5) }}
        </p>
        <p class="text-article">Productos</p>
        </div>
      </article>
      <article>
        <div class="content-article">

        <p class="text-counter">+ {{ dataR.delivery }} </p>
        <p class="text-article">Envios realizados</p>
        </div>
      
      </article>
    </div>
  </section>
</template>


<script lang="ts" setup>
import { ref, Ref } from "vue";

type statistic = {
  year: number,
  clients: number,
  product: number,
  delivery: number,
}


let dataR: Ref<statistic> = ref({
year: 0,
clients: 0,
product: 0,
delivery: 0
});


function showCounterAutomatic(start: number, limit: number,key: keyof statistic, speed: number) {
dataR.value[key] = start
let tiempo = setInterval(() => {
  dataR.value[key] += 1;
  if( dataR.value[key] == limit) {
    clearInterval(tiempo);
  }
}, speed)
}

showCounterAutomatic(0,35, 'year', 80)
showCounterAutomatic(9969,10000, 'clients', 80)
showCounterAutomatic(19969, 20000,'product', 80)
showCounterAutomatic(169,200, 'delivery', 80)



</script>

<style lang="scss" scoped>
@import './SectionStatistic.style.scss';
</style>