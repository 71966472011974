import {defineStore} from "pinia";


export const useNavbarStore = defineStore('navbar', {
    state: () => {
        return {
            style: 'ligth'
        }
    },
    getters: {},
    actions: {
        changeStyleNavbar(value: string) {
            this.$state.style = value
        }
    }
})